import React, { useState } from 'react';
import { FaTimes, FaStar, FaRegStar, FaPaperPlane } from 'react-icons/fa';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import './FeedbackModal.css';

const FeedbackModal = ({ isOpen, onClose, assistantModel, collectedResponses }) => {
  const [user] = useAuthState(auth);
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (rating === 0) {
      alert('Please select a rating before submitting.');
      return;
    }

    setIsSubmitting(true);

    const feedbackData = {
      assistantModel,
      collectedResponses: JSON.stringify(collectedResponses),
      rating,
      feedbackText,
      email: email || 'Anonymous',
      user: user ? user.uid : 'Guest',
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, '5DThinkingLessonPlanFeedback'), feedbackData);

      setSubmitted(true);
      
      setTimeout(() => {
        setRating(0);
        setFeedbackText('');
        setEmail('');
        setSubmitted(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="feedback-modal-overlay">
      <div className="feedback-modal-content">
        <button className="feedback-modal-close" onClick={onClose}>
          <FaTimes />
        </button>

        {!submitted ? (
          <>
            <h2>We Value Your Feedback!</h2>
            <p>Please share your experience with the 5D Lesson Planner</p>
            
            <form onSubmit={handleSubmit}>
              <div className="rating-container">
                <p>How would you rate your experience?</p>
                <div className="stars">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span 
                      key={star}
                      onClick={() => handleRatingClick(star)}
                      className="star-icon"
                    >
                      {star <= rating ? <FaStar /> : <FaRegStar />}
                    </span>
                  ))}
                </div>
              </div>
              
              <div className="feedback-input">
                <label htmlFor="feedback">What could we improve?</label>
                <textarea
                  id="feedback"
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                  placeholder="Tell us what you liked or how we can make the 5D Lesson Planner better..."
                  rows={4}
                />
              </div>
              
              <div className="email-input">
                <label htmlFor="email">Email (optional):</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="your@email.com"
                />
              </div>
              
              <button 
                type="submit" 
                className="submit-feedback-btn"
                disabled={rating === 0 || isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : (
                  <>
                    <FaPaperPlane style={{ marginRight: '8px' }} />
                    Submit Feedback
                  </>
                )}
              </button>
            </form>
          </>
        ) : (
          <div className="thank-you-message">
            <h2>Thank You!</h2>
            <p>Your feedback helps us improve the 5D Lesson Planner.</p>
            <div className="submitted-rating">
              {[1, 2, 3, 4, 5].map((star) => (
                <span key={star} className="star-icon">
                  {star <= rating ? <FaStar /> : <FaRegStar />}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
